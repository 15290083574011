<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <Logo />
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="skin === 'dark'
              ? require('@/views/auth/signUp/assets/illustration-dark.svg')
              : require('@/views/auth/signUp/assets/illustration.svg')"
            alt="Register V2"
          />
        </div>
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ t('auth.signUp.title') }}
          </b-card-title>

          <!-- form -->
          <b-form class="auth-register-form mt-2">
            <!-- email -->
            <b-form-group
              :label="t('auth.field.email')"
              label-for="register-email"
            >
              <b-form-input
                id="register-email"
                v-model="model.email"
                name="register-email"
                :state="errors.email ? false : null"
                placeholder="ivanivanov@yandex.ru"
              />
              <small
                v-if="errors.email"
                class="text-danger"
              >
                {{ errors.email }}
              </small>
            </b-form-group>

            <BRow>
              <BCol>
                <!-- firstname -->
                <b-form-group
                  :label="t('auth.field.firstname')"
                  label-for="register-firstname"
                >
                  <b-form-input
                    id="register-firstname"
                    v-model="model.firstname"
                    name="register-firstname"
                    :state="errors.firstname ? false : null"
                    placeholder="Иван"
                  />
                  <small
                    v-if="errors.firstname"
                    class="text-danger"
                  >
                    {{ errors.firstname }}
                  </small>
                </b-form-group>
              </BCol>
              <BCol>
                <!-- surname -->
                <b-form-group
                  :label="t('auth.field.surname')"
                  label-for="register-firstname"
                >
                  <b-form-input
                    id="register-firstname"
                    v-model="model.surname"
                    name="register-firstname"
                    :state="errors.surname ? false : null"
                    placeholder="Иванов"
                  />
                  <small
                    v-if="errors.surname"
                    class="text-danger"
                  >
                    {{ errors.surname }}
                  </small>
                </b-form-group>
              </BCol>
            </BRow>

            <!-- phone -->
            <PhoneInput
              ref="numberInput"
              v-model="model.phone"
              :label="t('auth.field.phone')"
            />

            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="isAcceptedAgreement"
                name="checkbox-1"
              >
                <i18n
                  tag="span"
                  path="auth.signUp.agreement.text"
                >
                  <template #privacy>
                    <b-link :to="{ name: 'privacyPolicy' }">
                      {{ t('auth.signUp.agreement.privacy') }}
                    </b-link>
                  </template>
                  <!-- <template #terms>
                    <b-link>{{ t('auth.signUp.agreement.terms') }}</b-link>
                  </template> -->
                </i18n>
              </b-form-checkbox>
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="!isAcceptedAgreement"
              @click.prevent="onSubmit"
            >
              <b-spinner
                v-if="isProcessing"
                small
              />
              <template v-else>
                {{ $t('auth.signUp.submit') }}
              </template>
            </b-button>
          </b-form>

          <i18n
            path="auth.signUp.signInText"
            tag="p"
            class="text-center mt-2"
          >
            <template #signInLink>
              <b-link :to="{ name:'sign-in' }">
                {{ $t('auth.signUp.signInLink') }}
              </b-link>
            </template>
          </i18n>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BCardTitle,
  BSpinner,
} from 'bootstrap-vue';
import {computed, inject, ref} from '@vue/composition-api';

import { required, email } from '@validations';

import Logo from '@core/layouts/components/Logo.vue';
import { getToken } from "firebase/messaging";
import PhoneInput from '@/components/phoneInput/PhoneInput.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useValidation } from '@/hooks/useValidation';
import { emailValidator, fieldRequiredValidator } from '@/utils/validation';
import browserInfo from "@/hooks/browserInfo";

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BSpinner,
    PhoneInput,
  },
 setup() {
    const { t } = useI18n();
    const store = useStore();
    const { welcomeToast, dangerToast } = useToast();
    const skin = computed(() => store.getters['appConfig/skin']);

    const model = ref({
      email: '',
      firstname: '',
      surname: '',
      phone: '',
        deviceUid: null,
        deviceName: null,
    });
    const [validate, errors] = useValidation([
      fieldRequiredValidator(model.value, 'email', t('errors.message.emailRequired')),
      emailValidator(model.value, 'email', t('errors.message.emailInvalid')),
      fieldRequiredValidator(model.value, 'firstname', t('errors.message.passwordRequired')),
      fieldRequiredValidator(model.value, 'surname', t('errors.message.passwordRequired')),
    ]);

    const isAcceptedAgreement = ref(false);
    const numberInput = ref(null);
    const isProcessing = ref(false);
    const firebase = inject('firebase');
    if (window.Notification) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(firebase.messaging, {vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY})
          .then((currentToken) => {
            if (currentToken) {
              model.value.deviceUid = currentToken;
              model.value.deviceName = `${browserInfo.browserName} ${browserInfo.majorVersion}`;
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
        }
      });
    } else {
      console.log('Firebase Not Supported');
    }

    const onSubmit = async () => {
      if (isProcessing.value) return;

      const isFormValid = validate();
      const isNumberValid = numberInput.value.validate();
      if (!isFormValid || !isNumberValid) return;

      isProcessing.value = true;
      const {
        result,
        data,
        status,
      } = await store.dispatch('user/signUp', model.value);
      isProcessing.value = false;

      if (result) {
        const { firstname } = data;

        welcomeToast(
          t('auth.welcome.title', { firstname }),
          t('auth.welcome.text'),
        );
        return;
      }

      if (status !== 422) return;

      const isEmailError = data?.errors?.email;
      const isPhoneError = data?.errors?.phone;

      if (isEmailError) {
        dangerToast(
          t('auth.signUp.error'),
          t('auth.signUp.errors.emailExists'),
        );
      }

      if (isPhoneError) {
        dangerToast(
          t('auth.signUp.error'),
          t('auth.signUp.errors.phoneExists'),
        );
      }
    };

    return {
      t,

      skin,

      model,
      errors,

      isAcceptedAgreement,

      numberInput,

      isProcessing,
      onSubmit,

      required,
      email,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
